var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-btn", { attrs: { icon: "", "x-large": "" } }, [
    _c("img", {
      attrs: {
        src: require("@/assets/images/icons/icon-menu.svg"),
        height: "40",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }